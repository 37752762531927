import React, {useContext} from 'react'
import {BrowserRouter as Router, Route, Routes as ReactRouterRoutes, Navigate, useLocation} from 'react-router-dom'
import {dispatch} from 'f-web-app/store'
import {ThemeContext} from 'f-web-app'

function PrivateRoute({element}) {
  const location = useLocation()
  const _element =
    dispatch.user.getIsLoggedIn() === true ? element : <Navigate to={{pathname: `/auth${location.pathname}`}} />
  return _element
}

export default function Routes() {
  const {Home, Menu, Auth, Cart, Payment, User, Page404} = useContext(ThemeContext)
  return (
    <Router>
      <ReactRouterRoutes>
        <Route path="/" exact element={<Home.Home />} />
        <Route path="/menu" element={<Menu.Menu />} />
        <Route path={'/auth/:nextRoute/:nextRoute2?'} element={<Auth.Auth />} />
        <Route path="/cart" element={<Cart.Cart />} />
        <Route path="/payment/:nextRoute?" element={<PrivateRoute element={<Payment.Payment />} />} />
        <Route path="/user" element={<PrivateRoute element={<User.User />} />} />
        <Route path="*" element={<Page404.Page404 />} />
      </ReactRouterRoutes>
    </Router>
  )
}

import React, {useContext} from 'react'
import {FView} from 'f-web-app/components'
import {dispatch} from 'f-web-app/store'
import {useSelector} from 'react-redux'
import {ThemeContext} from 'f-web-app'

export default function NavbarView({className}) {
  const {Components} = useContext(ThemeContext)
  const cartCount = useSelector(dispatch.user.getCartCount)
  const isLoggedIn = useSelector(dispatch.user.getIsLoggedIn)

  return (
    <FView w="100%" row justifyBetween alignCenter h="5rem" maxWidth={'42rem'} className={className}>
      <Components.IconLink label="Menu" icon="utensils" to="/menu" />
      <Components.IconLink label={`Cart (${cartCount})`} icon="shopping-cart" to="/cart" />
      {isLoggedIn ? (
        <Components.IconLink label="User" icon="user" to="/user" />
      ) : (
        <Components.IconLink label="Login" icon="sign-in-alt" to="/auth/menu" />
      )}
    </FView>
  )
}

import React, {useContext} from 'react'
import {css} from 'emotion'
import {NavLink} from 'react-router-dom'
import {FView} from 'f-web-app/components'
import {useIsTop, useMediaMinMD} from 'f-web/src/hooks'
import {ThemeContext} from 'f-web-app'

export default function PageHeader({fixedHeight, className, ...props}) {
  const isTop = useIsTop()
  const isMinMD = useMediaMinMD()
  const {themeColors, Components} = useContext(ThemeContext)
  return (
    <FView
      h={isMinMD ? (isTop || fixedHeight ? '10rem' : '6rem') : isTop || fixedHeight ? '12rem' : '6rem'}
      bg={themeColors.white75}
      className={css(classes.container, className)}
      {...props}
    >
      <FView fill row={isMinMD} center={!isMinMD} className={classes.innerContainer}>
        <FView fill={!isMinMD} justifyCenter>
          <NavLink to="/">
            <FView maxWidth="28rem" mh="3rem">
              <Components.LogoView fixedHeight={fixedHeight} />
            </FView>
          </NavLink>
        </FView>
        <Components.LocationSelect />
        <Components.NavbarView />
      </FView>
    </FView>
  )
}

const classes = {
  container: css({
    transition: 'height 0.5s',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 .1rem .6rem rgba(0,0,0,0.2)',
    zIndex: 99,
    position: 'fixed',
    top: 0,
  }),
  innerContainer: css({
    width: '90%',
    maxWidth: 1024,
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
}

import React, {useContext} from 'react'
import {NavLink} from 'react-router-dom'
import {ThemeContext} from 'f-web-app'

export default function OrderNowButton({label = 'ORDER NOW', heading, textProps}) {
  const {Components} = useContext(ThemeContext)

  return (
    <NavLink to="/menu">
      <Components.PaintedButtonView black heading={heading} textProps={textProps} label={label} />
    </NavLink>
  )
}

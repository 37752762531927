import React, {Component} from 'react'
import {FButton, FSelect, FInput} from 'f-web-app/components'
import * as utils from 'f-utils'
import {dispatch} from 'f-web-app/store'
import {ThemeContext} from 'f-web-app'
import {useNavigate} from 'react-router-dom'

export default function UserDetailsWithRouter() {
  const navigate = useNavigate()
  return <UserDetails navigate={navigate} />
}
class UserDetails extends Component {
  state = {
    edit: '',
  }
  nameInput = React.createRef()
  phoneInput = React.createRef()
  render() {
    return (
      <ThemeContext.Consumer>
        {({Components, User}) => (
          <User.UserDetailsView
            email={<FSelect selector={dispatch.user.getEmail} />}
            name={<FSelect selector={dispatch.user.getName} />}
            NameInputElement={
              <FSelect selector={dispatch.user.getName}>
                {(name) =>
                  this.state.edit === 'name' ? (
                    <FInput
                      ref={this.nameInput}
                      maxLength="18"
                      defaultValue={name}
                      onKeyPress={this._handleKeyPress}
                      {...User.nameInputProps}
                    />
                  ) : (
                    <User.UserDetailsTextView text={name ? name : 'Update Name'} />
                  )
                }
              </FSelect>
            }
            PhoneNumberInputElement={
              <FSelect selector={dispatch.user.getPhoneNumber}>
                {(phoneNumber) =>
                  this.state.edit === 'phone' ? (
                    <FInput
                      ref={this.phoneInput}
                      maxLength="10"
                      defaultValue={phoneNumber}
                      onKeyPress={this._handleKeyPress}
                      {...User.phoneNumberInputProps}
                    />
                  ) : (
                    <User.UserDetailsTextView text={utils.formatPhoneNumber(phoneNumber)} />
                  )
                }
              </FSelect>
            }
            edit={this.state.edit}
            CancelEditButtonElement={
              <FButton onClick={() => this.setState({edit: ''})}>
                <Components.CancelButtonView />
              </FButton>
            }
            SaveNameButtonElement={
              <FButton onClick={() => this._handleButtonClick('saveName')}>
                <Components.CheckButtonView />
              </FButton>
            }
            SavePhoneNumberButtonElement={
              <FButton onClick={() => this._handleButtonClick('savePhone')}>
                <Components.CheckButtonView />
              </FButton>
            }
            EditNameButtonElement={
              <FButton onClick={() => this._handleButtonClick('name')}>
                <Components.EditButtonView />
              </FButton>
            }
            EditPhoneNumberButtonElement={
              <FButton onClick={() => this._handleButtonClick('phone')}>
                <Components.EditButtonView />
              </FButton>
            }
            PaymentRowElement={
              <FSelect selector={dispatch.user.getHasPayment}>
                {(hasPayment) =>
                  hasPayment && (
                    <User.UserDetailsPaymentView
                      last4={<FSelect selector={dispatch.user.getLast4} />}
                      EditCCButtonElement={
                        <FButton onClick={() => this._handleButtonClick('payment')}>
                          <Components.EditButtonView />
                        </FButton>
                      }
                    />
                  )
                }
              </FSelect>
            }
          />
        )}
      </ThemeContext.Consumer>
    )
  }
  _handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this._handleButtonClick('save')
    }
  }
  _handleButtonClick = (name) => {
    if (name === 'name') {
      this.setState({edit: 'name'})
    } else if (name === 'phone') {
      this.setState({edit: 'phone'})
    } else if (name === 'saveName') {
      const userName = this.nameInput.current.value
      if (userName.length === 0) {
        dispatch.notification.setMessage({
          message: 'Name cannot be empty!',
          level: 'success',
        })
        return
      }
      dispatch.user
        .changeUserInfo({name: userName})
        .then(() => {
          dispatch.notification.setMessage({
            message: 'User Info Saved!',
            level: 'success',
          })
        })
        .catch((e) => {
          dispatch.notification.setMessage({
            message: e.message,
            level: 'error',
          })
        })
      this.setState({edit: ''})
    } else if (name === 'savePhone') {
      const phoneNumber = this.phoneInput.current.value
      dispatch.user
        .changeUserInfo({phoneNumber})
        .then(() => {
          dispatch.notification.setMessage({
            message: 'User Info Saved!',
            level: 'success',
          })
        })
        .catch((e) => {
          dispatch.notification.setMessage({
            message: e.message,
            level: 'error',
          })
        })
      this.setState({edit: ''})
    } else if (name === 'payment') {
      this.props.navigate('/payment/user')
    }
  }
}

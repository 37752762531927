import React, {useContext} from 'react'
import {NavLink} from 'react-router-dom'
import {ThemeContext} from 'f-web-app'

export default function Page404() {
  const {Page404} = useContext(ThemeContext)

  return (
    <Page404.Page404View
      MenuButtonElement={
        <NavLink to="/menu">
          <Page404.MenuButtonView />
        </NavLink>
      }
    />
  )
}

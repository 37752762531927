import React, {useContext} from 'react'
import {FView, FText} from 'components'
import {ThemeContext} from 'f-web-app'

const UserDetailsView = ({
  email,
  edit,
  NameInputElement,
  PhoneNumberInputElement,
  CancelEditButtonElement,
  SaveNameButtonElement,
  SavePhoneNumberButtonElement,
  EditNameButtonElement,
  EditPhoneNumberButtonElement,
  PaymentRowElement,
}) => {
  const {themeColors} = useContext(ThemeContext)
  return (
    <FView>
      <>
        <FText bold h7 black>
          Email:
        </FText>
        <FView mt={'1rem'} h={'5.7rem'} justifyCenter pl={'1.5rem'} mr={'6rem'} rounded>
          <FText h7 grey>
            {email}
          </FText>
        </FView>
      </>
      <FView size={'1.5rem'} />
      <>
        <FText bold h7 black>
          Full Name:
        </FText>
        <FView row alignCenter>
          <FView
            fill
            mt={'1rem'}
            h={'5.7rem'}
            rounded
            style={{border: edit === 'name' ? '1px solid ' + themeColors.grey50 : 'none'}}
            justifyCenter
          >
            {NameInputElement}
          </FView>
          <FView size={'6rem'} center pl="1.5rem">
            {edit === 'name' ? (
              <FView row>
                {CancelEditButtonElement}
                <FView size={'1rem'} />
                {SaveNameButtonElement}
              </FView>
            ) : (
              EditNameButtonElement
            )}
          </FView>
        </FView>
      </>
      <FView size={'1.5rem'} />
      <>
        <FText bold h7 black>
          Phone Number:
        </FText>
        <FView row alignCenter>
          <FView
            fill
            mt={'1rem'}
            h={'5.7rem'}
            rounded
            style={{border: edit === 'phone' ? '1px solid ' + themeColors.grey50 : 'none'}}
            justifyCenter
          >
            {PhoneNumberInputElement}
          </FView>
          <FView size={'6rem'} center pl="1.5rem">
            {edit === 'phone' ? (
              <FView row>
                {CancelEditButtonElement}
                <FView size={'1rem'} />
                {SavePhoneNumberButtonElement}
              </FView>
            ) : (
              EditPhoneNumberButtonElement
            )}
          </FView>
        </FView>
      </>
      <FView size={'1.5rem'} />
      {PaymentRowElement}
    </FView>
  )
}

export default UserDetailsView

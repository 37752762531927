import React from 'react'
import {createRoot} from 'react-dom/client'
import './index.css'
import createWebApp from './f-web-app/createWebApp'
import * as serviceWorker from 'f-web-app/serviceWorker'
import * as pages from './pages'
import * as Components from './components'
import * as images from './images'
import {COLOR, TEXT} from './styles'

const WebApp = createWebApp({pages, Components, COLOR, TEXT, images})

const root = createRoot(document.getElementById('root'))
root.render(<WebApp />)

serviceWorker.unregister()

import React, {useContext} from 'react'
import {dispatch} from 'f-web-app/store'
import {ThemeContext} from 'f-web-app'
import {useNavigate} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {FButton, FView} from 'f-web-app/components'

export default function User() {
  const loading = useSelector(dispatch.user.getIsLoading)
  const {themeColors, User, Components} = useContext(ThemeContext)
  const navigate = useNavigate()
  return (
    <User.UserView>
      {loading ? (
        <FView h={'50rem'} center>
          <Components.Spinner color={themeColors.backgroundText} />
        </FView>
      ) : (
        <User.UserLoadedView
          UserDetailsElement={<User.UserDetails.UserDetails />}
          LogoutButtonElement={
            <FButton
              onClick={() => {
                navigate('/')
                dispatch.user.signOut()
                dispatch.notification.setMessage({
                  message: 'Successfully logged out!',
                  level: 'success',
                })
              }}
            >
              <Components.PaintedButtonView label="LOGOUT" />
            </FButton>
          }
        />
      )}
    </User.UserView>
  )
}

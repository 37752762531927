import React, {Component, createRef} from 'react'
import {connect} from 'react-redux'
import NotificationSystem from 'react-notification-system'

class Notification extends Component {
  notificationSystemRef = createRef()
  UNSAFE_componentWillReceiveProps(newProps) {
    const {message, level, position = 'bc'} = newProps.notification
    this.notificationSystemRef.current.addNotification({
      message,
      level,
      position,
    })
  }
  render() {
    return <NotificationSystem ref={this.notificationSystemRef} />
  }
}

function mapState(state) {
  return {
    notification: state.notification,
  }
}

export default connect(mapState)(Notification)

import React, {Component} from 'react'
import {useNavigate} from 'react-router-dom'
import {dispatch} from 'f-web-app/store'
import {ThemeContext} from 'f-web-app'
import {FButton, FInput} from 'f-web-app/components'

class Payment extends Component {
  state = {
    number: '',
    expiry: '',
    cvc: '',
    postal: '',
    saving: false,
  }
  render() {
    const {expiry, number, cvc, postal, saving} = this.state
    const fields = {
      number: this._formatNumber(number),
      expiry: this._formatExpiry(expiry),
      cvc,
      postal,
    }
    return (
      <ThemeContext.Consumer>
        {({Payment}) => (
          <Payment.PaymentView
            CardNumberInputElement={
              <FInput
                maxLength="19"
                name="cardnumber"
                autocomplete="cc-number"
                placeholder="Card Number"
                onChange={this._handleInputChange}
                value={fields.number}
                {...Payment.cardNumberInputProps}
              />
            }
            ExpiryInputElement={
              <FInput
                maxLength="5"
                name="cc-exp"
                autocomplete="cc-exp"
                placeholder="Expiry"
                onChange={this._handleInputChange}
                value={fields.expiry}
                {...Payment.expiryInputProps}
              />
            }
            CVCInputElement={
              <FInput
                maxLength="4"
                name="cvc"
                autocomplete="cc-csc"
                placeholder="CVC"
                onChange={this._handleInputChange}
                value={fields.cvc}
                {...Payment.CVCInputProps}
              />
            }
            PostalInputElement={
              <FInput
                maxLength="7"
                name="postal-code"
                autocomplete="postal-code"
                placeholder="Postal Code / Zip"
                onChange={this._handleInputChange}
                value={fields.postal}
                {...Payment.PostalInputProps}
              />
            }
            SubmitButtonElement={
              <FButton disabled={saving} onClick={this._handleSubmitPress}>
                <Payment.SubmitButtonView disabled={saving} saving={saving} />
              </FButton>
            }
          />
        )}
      </ThemeContext.Consumer>
    )
  }
  _handleInputChange = (e) => {
    e.preventDefault()
    const {name, value} = e.target
    if (name === 'cardnumber') {
      const number = value.replace(/\D/g, '')
      this.setState({number})
    } else if (name === 'cc-exp') {
      const expiry = value.replace(/\D/g, '')
      this.setState({expiry})
    } else if (name === 'cvc') {
      const cvc = value.replace(/\D/g, '')
      this.setState({cvc})
    } else if (name === 'postal-code') {
      this.setState({postal: value})
    }
  }
  _handleSubmitPress = () => {
    const {number, expiry, cvc, postal} = this.state
    if (!postal || !postal.trim()) {
      dispatch.notification.setMessage({
        message: 'Please enter postal code or zip address',
        level: 'error',
      })
      return
    }
    this.setState({saving: true})
    const card = {
      number,
      cvc,
      exp_month: expiry.slice(0, 2),
      exp_year: `20${expiry.slice(2, 4)}`,
      address_zip: postal,
    }
    const {match} = this.props
    const {nextRoute} = match.params
    if (dispatch.user.getHasPayment()) {
      dispatch.user
        .updateCreditCard(card)
        .then(() => {
          this.setState({saving: false})
          this.props.navigate(`/${nextRoute}`)
          dispatch.notification.setMessage({
            message: 'Credit Card updated!',
            level: 'success',
          })
        })
        .catch((e) => {
          this.setState({saving: false})
          dispatch.notification.setMessage({
            message: 'Error updating card! ' + e.message,
            level: 'error',
          })
        })
    } else {
      dispatch.user
        .addCreditCard(card)
        .then(() => {
          this.setState({saving: false})
          this.props.navigate(`/${nextRoute}`)
          dispatch.notification.setMessage({
            message: 'Credit Card added!',
            level: 'success',
          })
        })
        .catch((e) => {
          this.setState({saving: false})
          dispatch.notification.setMessage({
            message: e.message,
            level: 'error',
          })
        })
    }
  }
  _formatNumber = (cc) => {
    return cc.replace(/(.{4})/g, '$1 ').trim()
  }
  _formatExpiry = (exp) => {
    if (exp.length < 3) {
      return exp
    }
    return exp.substr(0, 2) + '/' + exp.substr(2, 2)
  }
}

export default function PaymentWithRouter() {
  const navigate = useNavigate()
  return <Payment navigate={navigate} />
}

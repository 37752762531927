import React, {useContext} from 'react'
import {NavLink} from 'react-router-dom'
import {ThemeContext} from 'f-web-app'

export default function Logo() {
  const {Components} = useContext(ThemeContext)
  return (
    <NavLink to="/">
      <Components.LogoView />
    </NavLink>
  )
}
